<template>
  <moe-page title="优惠券入口管理">
    <moe-card class="mb-20" :autoHeight="true">
      <moe-form
        class="default-form"
        ref="paramsForm"
        :showBack="false"
        :showClose="true"
        :model="params"
        :rules="rules">
        <div style="width: 50%;" class="margin-0-auto">
          <el-form-item label="标题名称" prop="title">
            <el-input v-model.trim="params.title" placeholder="请输入标题名称" clearable maxlength="5"></el-input>
          </el-form-item>
        </div>

        <div style="height: 500px;min-height: 500px;" class="df fdc">
          <moe-table
            :stripe="true"
            :data="params.couponList"
            :mode="false"
            :showPage="false"
            :number-show="false"
            :params="{ pageNum: 1 }"
            emptyText="请添加优惠券"
            rowKey="id">
            <!-- 操作按钮 -->
            <el-form-item slot="tool" prop="couponList">
              <el-button type="primary" icon="el-icon-circle-plus-outline" @click="showDialog = true;">添加优惠券</el-button>
            </el-form-item>

            <el-table-column label="活动信息" min-width="200">
              <div class="df fdc aifs ml-30" slot-scope="{ row }">
                <div>{{ row.name }}</div>
                <div>券ID：{{ row.id }}</div>
              </div>
            </el-table-column>

            <el-table-column label="优惠方式" min-width="150">
              <template slot-scope="{ row }">
                {{ `满 ${row.condition} 元 减 ${row.discount} 元` }}
              </template>
            </el-table-column>

            <el-table-column label="使用时间" min-width="310">
              <div class="df fdc aic jcc" slot-scope="{ row }">
                <div v-if="row.timeType === 'TIME_RANGE'">{{ `${$moe_time.getTimeStamp(row.startTime)} 至 ${$moe_time.getTimeStamp(row.endTime)}` }}</div>
                <div v-if="row.timeType === 'RELATIVE'">{{ `领取后 ${row.effectiveDays} 天有效` }}</div>
              </div>
            </el-table-column>

            <el-table-column label="操作" min-width="350" fixed="right">
              <div class="moe-table_btns df aic jcc" slot-scope="{ row, $index }">
                <template v-if="$index > 0">
                  <el-button type="success" size="mini" icon="el-icon-upload2" @click="handleClick('topping', row, $index)">置顶</el-button>
                  <el-button type="primary" size="mini" icon="el-icon-top" @click="handleClick('top', row, $index)">上移</el-button>
                </template>
                <el-button v-if="$index < (params.couponList.length - 1)" type="primary" size="mini" icon="el-icon-bottom" @click="handleClick('bottom', row, $index)">下移</el-button>
                <el-button type="danger" size="mini" icon="el-icon-delete" @click="handleClick('delete', row, $index)">移除</el-button>
              </div>
            </el-table-column>
          </moe-table>
        </div>

        <template slot="tool">
          <el-button type="primary" icon="el-icon-upload2" :loading="paramsLoad" @click="submit()">提交</el-button>
        </template>
      </moe-form>
    </moe-card>

    <moe-dialog :show="showDialog" title="添加优惠券" width="70%" @close="handleDialogClose()">
      <div class="dialog_content">
        <moe-inquire @search="couponSearch">
          <el-form-item label="活动名称">
            <el-input v-model.trim="couponParams.name" placeholder="输入活动名称" maxlength="50" clearable />
          </el-form-item>
          <el-form-item label="券编号">
            <el-input :value="couponParams.id" @input="(value) => couponParams.id = value.replace(/^\.+|[^\d]/g, '')" placeholder="输入券编号" maxlength="50" clearable />
          </el-form-item>
        </moe-inquire>

        <moe-table
          v-if="showDialog"
          ref="couponTableRef"
          :maxHeight="480"
          url="/shop/coupon/list"
          :params="couponParams"
          :number-show="false"
          :defaultSelectIds="params.couponList && params.couponList.length ? params.couponList.map(({ id }) => id) : []"
          @selectChange="selectChange"
          rowKey="id">
          <el-table-column type="selection" reserve-selection width="60" />

          <el-table-column label="活动信息" min-width="200">
            <div class="df fdc aifs" slot-scope="{ row }">
              <div>{{ row.name }}</div>
              <div>券ID：{{ row.id }}</div>
            </div>
          </el-table-column>

          <el-table-column label="优惠方式" min-width="150">
            <template slot-scope="{ row }">
              {{ `满 ${row.condition} 元 减 ${row.discount} 元` }}
            </template>
          </el-table-column>

          <el-table-column label="使用时间" min-width="310">
            <div class="df fdc aic jcc" slot-scope="{ row }">
              <div v-if="row.timeType === 'TIME_RANGE'">{{ `${$moe_time.getTimeStamp(row.startTime)} 至 ${$moe_time.getTimeStamp(row.endTime)}` }}</div>
              <div v-if="row.timeType === 'RELATIVE'">{{ `领取后 ${row.effectiveDays} 天有效` }}</div>
            </div>
          </el-table-column>

          <el-table-column label="优惠券库存" prop="number" min-width="150" />

          <el-table-column label="限领数" min-width="150">
            <template slot-scope="{ row }">
              {{ `${row.quota === 0 ? '不限量' : row.quota}` }}
            </template>
          </el-table-column>
        </moe-table>
      </div>
      <template slot="footer">
        <el-button @click="handleDialogClose()">取消</el-button>
        <el-button type="primary" @click="handleConfirm()">确定</el-button>
      </template>
    </moe-dialog>
  </moe-page>
</template>

<script>
export default {
  name: 'ContentManageCouponManageAdd',
  data() {
    const verify = this.$moe_verify.verifyForm
    const rules = {
      name: verify.isExtent(['请输入活动名称', '长度为 2 ~ 16位'], '2~16'),
      title: verify.isEmpty('请输入标题名称'),
      couponList: verify.isEmpty('请添加优惠券'),
    }
    return {
      params: {
        title: '',
        id: 1,
        couponList: [],
      },
      rules,
      paramsLoad: false,
      showDialog: false,
      couponParams: {
        pageNum: 1,
        pageSize: 10,
        level: '',
        state: 'RECEIVING',
        name: '',
        id: '',
      },
      selectList: [],
    }
  },
  methods: {
    submit() {
      this.$refs['paramsForm'].validate(() => {
        this.paramsLoad = true;
        this.$moe_api.CONTENT.updateHome({
          ...this.params,
          couponList: this.params.couponList.map(({ id }, index) => {
            return {
              couponId: id,
              sort: index
            }
          })
        }).then((data) => {
          if (data.code === 200) {
            this.$moe_msg.success('提交成功', {
              completionHandler: () => {
                this.$moe_coordinator.navigateBack();
              }
            });
          } else {
            this.$moe_msg.error(data.message);
          }
        }).finally(() => {
          this.paramsLoad = false;
        })
      })
    },
    handleClick(type, { name }, index) {
      let { couponList } = this.params
      switch (type) {
        case 'delete':
          this.$moe_layer.confirm(`您确定要移除当前选项"${name}”吗？`, () => {
            couponList.splice(index, 1);
          });
          break;
        case 'topping':
          this.$moe_layer.confirm(`您确定要置顶当前选项"${name}"吗？`, () => {
            const row = this.$moe_lodash.cloneDeepData(couponList[index]);
            couponList.splice(index, 1);
            couponList.unshift(row);
            this.$moe_msg.success('置顶成功,提交后更新');
          });
          break;
        case 'top':
          this.$moe_layer.confirm(`您确定要上移当前选项"${name}”吗？`, () => {
            couponList.splice(index - 1, 2, couponList[index], couponList[index - 1]);
            this.$moe_msg.success('上移成功,提交后更新');
          });
          break;
        case 'bottom':
          this.$moe_layer.confirm(`您确定要下移当前选项"${name}”吗？`, () => {
            couponList.splice(index, 2, couponList[index + 1], couponList[index]);
            this.$moe_msg.success('下移成功,提交后更新');
          });
          break;
      }
    },
    couponSearch(isSearch) {
      if (!isSearch) {
        this.couponParams = {
          pageNum: 1,
          pageSize: 10,
          level: '',
          state: 'RECEIVING',
          name: '',
          id: '',
        }
      }

      this.$refs['couponTableRef'].searchData();
    },
    selectChange(list) {
      this.selectList = list;
    },
    handleConfirm() {
      this.selectList.forEach((item) => {
        let findItem = this.params.couponList.find(({ id }) => item.id === id);

        if (!findItem) {
          this.params.couponList.push(item)
        }
      })
      this.handleDialogClose();
    },
    handleDialogClose() {
      this.showDialog = false;
    },
    /* 获取优惠券组件内容 */
    getHomeComponentCoupon() {
      this.$moe_api.CONTENT.getHomeDetail({ id: this.$route.query.id }).then((data) => {
        if (data.code === 200) {
          let r = data.result;
          this.params = {
            id: r.id,
            title: r.title,
            couponList: [],
          };

          if (r.couponList && r.couponList.length) {
            this.getCouponListByIds(r.couponList);
          }
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    getCouponListByIds(couponList) {
      this.$moe_api.COUPON_API.getCouponListByIds({ ids: couponList.map(({ couponId }) => couponId).join(',') }).then((data) => {
        if (data.code === 200) {
          this.params.couponList = couponList.map(({ couponId }) => {
            let findItem = data.result.list.find((item) => couponId === item.id);
            return {
              ...findItem
            }
          })
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    }
  },
  mounted() {
    this.getHomeComponentCoupon();
  }
}
</script>

<style lang="scss">

</style>